import { Directive, Input, TemplateRef, ViewContainerRef}  from '@angular/core';
import { AppConfigService } from '../services/app-config.service';


@Directive({
  selector: '[ifModuleEnabled]'
})
export class IfModuleEnabledDirective {

  constructor(private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef) {}

  public readonly DISABLED_MODULES_CFG_KEY = 'disabledModules';

  @Input() set ifModuleEnabled(moduleName: string) {
    this.doCheck(moduleName);
  }

  protected doCheck(moduleName: string) {
    const disabledModule: boolean = AppConfigService[this.DISABLED_MODULES_CFG_KEY].some((disabledModule) => disabledModule === moduleName);
    if (disabledModule) {
      // remove template from DOM
      this.viewContainer.clear();
    } else {
      // add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
