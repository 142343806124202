<ng-container *ngIf="(mediaQueryService.breakpoints$ | async) as breakpoints">
  <div class="testimonial-container
              flex
              flex-col
              md:flex-row
              sm:flex-between-center
              gap-48
              md:gap-60"
              data-test-id="testimonial-container">
    <div class="testimonial-container-image flex flex-center-center flex-initial"
         [ngClass]="{ 'order-1': imageLeft || breakpoints.ltMd, 'order-2': !imageLeft && !breakpoints.ltMd }"
         data-test-id="testimonial-container-image">
      <img [attr.src]="testimonial.ImageUrl" alt="Testimonial image"/>
    </div>
    <div class="testimonial-container-text
                flex
                flex-col
                flex-initial
                gap-24
                md:gap-36
                md:max-w-[540]"
         data-test-id="testimonial-container-text"
         [ngClass]="{ 'order-2': imageLeft || breakpoints.ltMd, 'order-1': !imageLeft && !breakpoints.ltMd }">
      <mat-icon class="testimonial-container-text-quote-icon"
                data-test-id="testimonial-container-text-quote-icon"
                svgIcon="quote"
                color="primary"></mat-icon>
      <p [innerHTML]="testimonial.Text | trustedHtml"
         class="testimonial-container-text-paragraph
                typography-tiny-header
                md:typography-small-header
                leading-29
                font-light"
         data-test-id="testimonial-container-text-paragraph">
      </p>
      <div class="testimonial-container-text-author flex flex-col gap-4"
           data-test-id="testimonial-container-text-author">
        <span class="typography-paragraph font-bold testimonial-container-text-author-name"
              data-test-id="testimonial-container-text-author-name">
          {{ testimonial.Author.Name }}
        </span>
        <span class="typography-paragraph testimonial-container-text-author-position"
              data-test-id="testimonial-container-text-author-position">
          {{ testimonial.Author.Position }}
        </span>
      </div>
      <a *ngIf="testimonial.Link"
         [attr.href]="testimonial.Link.Link"
         target="_blank"
         type="button"
         mat-flat-button
         color="primary"
         class="testimonial-container-text-button"
         data-test-id="testimonial-container-text-button">{{ testimonial.Link.LinkText }}</a>
    </div>
  </div>
</ng-container>
