import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ChangeDetectorRef,
  Input,
  ViewChild, ElementRef, OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnDestroy {
  @Input() validFileTypes: string[];

  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: Function = function() {};

  /**
   * Selected file
   */
  file: File = null;

  /**
   * Get URL to browsers BLOB
   */
  blobUrl: string;

  @HostListener('change', ['$event.target.files']) emitFile(
    addedFiles: FileList
  ) {
    this.handleChange(addedFiles.item(0));
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  writeValue(value: File) {
    if (value) {
      if (value.size === 0) {
        this.reset();
        return;
      }

      this.file = value;
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: Function) {}

  private handleChange(addedFile: File) {
    if (addedFile) {
      if (typeof this.onChange === 'function') {
        this.onChange(addedFile);
      }
      // refresh selected file
      this.file = addedFile;

      // create internal BLOB in browser to make possible file download
      this.blobUrl = window.URL.createObjectURL(this.file);

      // mandatory to refresh file name in template
      this.changeDetectorRef.markForCheck();
    }
  }

  reset() {
    // clear selected files array
    this.file = null;

    // revoking blob url
    window.URL.revokeObjectURL(this.blobUrl);
    // reset blob url
    this.blobUrl = null;

    if (typeof this.onChange === 'function') {
      // call registered change listener
      this.onChange(null);
    }

    this.fileUploadInput.nativeElement.value = null;

    // Mandatory because of OnPush strategy.
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    window.URL.revokeObjectURL(this.blobUrl);
  }
}
