import {
  Component,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor
} from '@angular/forms';
import { MediaQueryService } from '@my7n/ui';



@Component({
  selector: 'cv-parse',
  templateUrl: './cv-parse.component.html',
  styleUrls: ['./cv-parse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CvParseComponent,
      multi: true
    }
  ]
})
export class CvParseComponent implements ControlValueAccessor {
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  onChange: Function;
  private _file: File | null = null;

  get file(): File {
    return this._file || null;
  }

  @HostListener('change', ['$event.target.files']) emitFiles(
    addedFiles: FileList
  ) {
    this.handleChange(addedFiles);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public mediaQueryService: MediaQueryService
  ) {}

  writeValue(value: File) {
    if (value) {
      if (value.size === 0) {
        this.reset();
        return;
      }

      this._file = value;
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: Function) {}

  private handleChange(addedFiles: FileList) {
    if (addedFiles) {
      // Analyze only first File object
      if (typeof this.onChange === 'function') {
        this.onChange(addedFiles[0]);
      }
    }
  }

  private reset() {
    // clear selected files array
    this._file = null;
    this.fileUploadInput.nativeElement.value = '';

    if (typeof this.onChange === 'function') {
      // call registered change listener
      this.onChange([]);
    }

    // Mandatory because of OnPush strategy.
    this.changeDetectorRef.markForCheck();
  }
}
