import { Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { default as moment } from 'moment';


const DEFAULT_PRECISION = 'day';

@Pipe({
  name: 'countToDate'
})
export class CountToDatePipe implements PipeTransform {


  transform(value: any, precision = DEFAULT_PRECISION): any {
    const outputFormat = AppConfigService.DEFAULT_DATE_OUTPUT_FORMATS[precision.toLowerCase()]
      || AppConfigService.DEFAULT_DATE_OUTPUT_FORMATS[DEFAULT_PRECISION];

    if (!value) {
      return undefined;
    }

    const date = moment.utc(value);

    if (!date.isValid()) {
      console.error(`[countToDate] Date ${value} has invalid format`);
      return undefined;
    }

    return date.local().calendar(null, {
      sameDay: function (now) {
        if (this.isAfter(now)) {
          return '[Today]';
        } else {
          return outputFormat;
        }
      },
      nextDay: '[Tomorrow]',
      nextWeek: outputFormat,
      lastDay: outputFormat,
      lastWeek: outputFormat,
      sameElse: outputFormat
  });
  }
}
