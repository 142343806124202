import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Services
import { StorageCacheService } from './storage-cache.service';
import { AppConfigService } from './app-config.service';
// Classes
import { CvSkill } from '../classes/cv-skill';
// Interfaces
import { ISkillsCategoriesDict } from '../interfaces/skills-categories-dict';
import { ICvSkill } from '../interfaces/cv-skill';
import { ICvSkillCore } from '../interfaces/cv-skill-core';
import { ISkill } from '../interfaces/skill';
import { ISkillsDict } from '../interfaces/skills-dict';
import { IDictSkillCategory } from '../interfaces/dict-skill-category';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { Observable, catchError, map, throwError, take, filter } from 'rxjs';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';


const CUSTOM_SKILL_CATEGORY_NAME = 'Other';

@Injectable()
export class SkillsService {
  /**
   * Dictionaries API prefix.
   * @type {string}
   */
  readonly DICT_API_PREFIX: string;
  readonly CUSTOM_SKILL_CATEGORY_NAME = CUSTOM_SKILL_CATEGORY_NAME;
  readonly LANGUAGE_SKILLS_CATEGORY_ID = 11;
  customSkillCategoryId = -1;
  
  private _currentDictionaryId: string;
  private _userId: string;

  set currentDictionaryId(value: string) {
    if(!value) {
      this._currentDictionaryId = this._userId;
    } else {
      this._currentDictionaryId = value;
    }
  }

  get currentDictionaryId(): string {
    return this._currentDictionaryId;
  }

  private localSkills: any = [];

  constructor(private storageCacheService: StorageCacheService,
              private appConfigService: AppConfigService,
              private http: HttpClient,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService
              ) {
    this.DICT_API_PREFIX = appConfigService.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/dictionaries/';

    globalAppConfigFacadeService.user$.pipe(filter(user => !!user), take(1), map(user => user.Id)).subscribe(userId => {
      this._userId = userId;
      this._currentDictionaryId = userId;
    });
  }

  static filterOnlyContainingSkills(skillCategory: IDictSkillCategory) {
    return skillCategory.Skills.length > 0;
  }

  static mapFromDictSkill(skillCategory, skill): CvSkill {
    skill.CategoryName = skillCategory.Name;
    skill.SkillId = skill.SkillId || skill.Id;

    return new CvSkill(skill.Name, skill.Id, skillCategory.Name, skillCategory.Id);
  }

  static sortByLengthThenAlphabetically(skillA: ICvSkill, skillB: ICvSkill): number {
    if (skillA.Name.length > skillB.Name.length) {
      return 1;
    }

    if (skillB.Name.length > skillA.Name.length) {
      return -1;
    }

    // Alphabetically
    return skillA.Name.localeCompare(skillB.Name);
  }

  static sortAlphabetically(skillA: ICvSkillCore, skillB: ICvSkillCore): number {
    return skillA.Name.localeCompare(skillB.Name);
  }

  setCurrentDictionaryId(id: string) {
    this.currentDictionaryId = id;
  }

  getSkillsDict(): Observable<ISkillsDict> {
    return this.http.get<ISkillsCategoriesDict>(this.DICT_API_PREFIX + 'skills' + `/${this.currentDictionaryId}`).pipe(
      map(result => {
        const skills = Array.prototype.concat.apply([], result.SkillsCategories.filter(SkillsService.filterOnlyContainingSkills).map(function (skillCategory) {
          return skillCategory.Skills.map(SkillsService.mapFromDictSkill.bind(null, skillCategory));
        }));
  
        const dictCategories = result.SkillsCategories.map(skillCategory => {
          if (skillCategory.Name === CUSTOM_SKILL_CATEGORY_NAME) {
            this.customSkillCategoryId = skillCategory.Id;
          }
  
          return {
            Id: skillCategory.Id,
            Name: skillCategory.Name
          };
        });
  
        const dictCategoriesWithoutLanguages = dictCategories.filter((category) => category.Id !== this.LANGUAGE_SKILLS_CATEGORY_ID);
        const dictSkills = skills.filter((skill) => skill.CategoryId !== this.LANGUAGE_SKILLS_CATEGORY_ID).sort(SkillsService.sortByLengthThenAlphabetically);
        const dictLanguageSkills = skills.filter((skill) => skill.CategoryId === this.LANGUAGE_SKILLS_CATEGORY_ID).sort(SkillsService.sortAlphabetically);
  
        // Remove localSkills to avoid duplicates
        this.localSkills.length = 0;
  
        return {
          Categories: dictCategories,
          CategoriesWithoutLanguages: dictCategoriesWithoutLanguages,
          Skills: dictSkills,
          LanguageSkills: dictLanguageSkills
        };
      }),
      catchError(error => {
        console.error('[SkillsService] Failed to get CV Skills', error);
        return throwError(() => error);
      })
    )
  }

  getLocalSkills() {
    return this.localSkills;
  }

  invalidate() {
    // This will force config to reload, thus fetch new timekeys for skills dictionary and trigger
    // reload of skills from dictionary
    this.globalAppConfigFacadeService.updateConfigDirtyState(true);

    // Instead of config invalidation can also be used for dropping cache entries only
    // but then mechanism to refresh timekeys in Config should also be used
    console.debug('[SkillsService] Service invalidated, next usage will be updated with HTTP');
  }

  addLocalSkill(skill) {
    // user skill should be first
    this.localSkills.unshift(skill);
  }

  updateLocalSkillId(skillToUpdate) {
    const foundSkill = this.localSkills.find(function (localSkill) {
      return localSkill.Name = skillToUpdate.Name;
    });

    if (foundSkill) {
      foundSkill.SkillId = skillToUpdate.SkillId;
    }

    this.invalidate();
  }

  getAllSkills(): Observable<Array<ISkill>> {
    return this.getSkillsDict().pipe(
      map((dicts) => {
        return [ ...this.localSkills, ...dicts.Skills ];
      })
    );
  }
}
