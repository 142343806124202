import {
  AfterViewInit,
  Directive,
  ElementRef,
  Host,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[formGroup][scrollToError]'
})
export class ScrollToErrorDirective implements AfterViewInit, OnDestroy {
  @Input() scrollToError: Observable<void>
  subscriptions$ = new Subscription();
  constructor(private elementRef: ElementRef, @Optional() @Host() private formDirective: FormGroupDirective) {}

  findFirstInvalidControl(): HTMLElement {
    if (this.formDirective.form) {
      const firstInvalidControl = Object.keys(this.formDirective.form.controls).find((controlName) => {
        const control = this.formDirective.form.get(controlName);
        let controlElement: HTMLElement;
        if(control?.invalid){
          controlElement = this.elementByControlName(controlName) || this.elementByReflectName(controlName);
        }
        return controlElement;
      });

      return this.elementByControlName(firstInvalidControl) || this.elementByReflectName(firstInvalidControl);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions$.add(this.scrollToError.subscribe(() => {
      const firstInvalidControl = this.findFirstInvalidControl();
      
      if (this.formDirective.form.status === 'INVALID' && firstInvalidControl) {
        firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }));
  }

  private elementByControlName(name: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(`[formControlName="${name}"]`)
  }

  private elementByReflectName(name: string): HTMLElement {
    return this.elementRef.nativeElement.querySelector(`[ng-reflect-name="${name}"]`)
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
