import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'file-upload-element',
  templateUrl: './file-upload-element.component.html',
  styleUrls: ['./file-upload-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadElementComponent {
  @Input() file: File;
  @Input() blobUrl: string;

  @Output() removeFile = new EventEmitter<number>();

  emitFileRemoval() {
    this.removeFile.emit();
  }
}
