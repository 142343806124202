import { Component } from '@angular/core';
import { MatDialogService } from '@my7n/ui';
import { PrintSettingsDialogComponent } from './print-settings-dialog/print-settings-dialog.component';

@Component({
  selector: 'print-settings',
  templateUrl: './print-settings.component.html'
})
export class PrintSettingsComponent {
  constructor(private dialog: MatDialogService) {}

  showPrintSettingsModal(): void {
    this.dialog.openResponsiveDialog(PrintSettingsDialogComponent, {
      panelClass: 'print-settings-dialog-panel'
    });
  }
}
