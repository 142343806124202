// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ClassType = new(...args: any[]) => any;

export type Feature = string;

export interface FeatureDescription {
  Module: string;
  Target: string;
  Feature: string;
  Type: string;
  Description: string;
}

export interface FrontendFeatureDecorator {
  module?: string;
  summary: string;
}

export type FrontendFeaturesModuleDecorator = string;
