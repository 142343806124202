import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { PreviewService } from '../../../../services/preview.service';

@Component({
  selector: 'file-upload-pdf-element',
  templateUrl: './file-upload-pdf-element.component.html',
  styleUrls: ['./file-upload-pdf-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadPdfElementComponent {
  @Input() file: File;
  @Input() fileArrayBuffer: ArrayBuffer;
  @Input() blobUrl: string;
  @Input() converting: boolean;

  @Output() removeFile = new EventEmitter<number>();

  constructor(private previewService: PreviewService) {}

  emitFileRemoval() {
    this.removeFile.emit();
  }

  openFile($event: Event) {
    $event.preventDefault();
    window.open(this.blobUrl, '_blank');
  }
}
