import {
  Component,
  Input,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  ValidationErrors
} from '@angular/forms';

import { DEFAULT_VALID_FILE_EXT } from '../../../interfaces/file-upload.interface';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'files-upload',
  templateUrl: './files-upload.component.html',
  styleUrls: ['./files-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FilesUploadComponent,
      multi: true
    }
  ]
})
export class FilesUploadComponent implements ControlValueAccessor {
  @Input() validFileTypes = DEFAULT_VALID_FILE_EXT;
  @Input() currentValidationMessage: { error: string; message: string };
  @Input() controlErrorObject: ValidationErrors;
  @Input() mainDescription = 'Drag and drop here ';
  @Input() secondaryDescription = '';
  @Input() filesUploadButtonLabel = 'browse file';

  @ViewChild('filesUploadButton') fileUploadInput: ElementRef;

  onChange: Function;
  private _files: File[] | null = [];

  get files(): File[] {
    return this._files || [];
  }

  get filesUrls(): Array<string> {
    return this._files
      ? this._files.map((file: File) => {
          return window.URL.createObjectURL(file);
        })
      : [];
  }

  @HostListener('change', ['$event.target.files']) emitFiles(
    addedFiles: FileList
  ) {
    this.handleChange(addedFiles);
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public mediaQueryService: MediaQueryService
  ) {}

  writeValue(value) {
    if (value) {
      if (!value.length) {
        this.reset();
        return;
      }

      this._files = value;
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: Function) {}

  filesDropped(event: FileList) {
    this.handleChange(event);
  }

  removeFile(index: number) {
    const filesCopy = [...this._files];
    filesCopy.splice(index, 1);
    this._files = filesCopy;
    if (typeof this.onChange === 'function') {
      this.onChange(filesCopy);
    }
    if (this._files.length === 0) {
      this.reset();
    }
  }

  private handleChange(addedFiles: FileList) {
    if (addedFiles) {
      const files = [...this.files, ...Array.from(addedFiles)];
      if (typeof this.onChange === 'function') {
        this.onChange(files);
      }
      this._files = files;
    }
  }

  private reset() {
    // clear selected files array
    this._files = [];
    this.fileUploadInput.nativeElement.value = '';

    if (typeof this.onChange === 'function') {
      // call registered change listener
      this.onChange([]);
    }

    // Mandatory because of OnPush strategy.
    this.changeDetectorRef.markForCheck();
  }
}
