import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PDF_VALID_FILE_EXT } from '../../../interfaces/file-upload.interface';
import {
  ImageToPdfConverter,
  IGeneratedPdfFiles
} from '../../../classes/image-to-pdf-converter';
import { take } from 'rxjs/operators';

@Component({
  selector: 'file-upload-pdf',
  templateUrl: './file-upload-pdf.component.html',
  styleUrls: ['./file-upload-pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadPdfComponent,
      multi: true
    }
  ]
})
export class FileUploadPdfComponent implements ControlValueAccessor, OnDestroy {
  @Input() validFileTypes: string[];
  @Input() maxSizeToConvertMb: number;

  @ViewChild('fileUploadButton') fileUploadInput: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: Function = function () {};

  /**
   * Selected file
   */
  file: File = null;

  /**
   * Array buffer 0f file
   */
  fileArrayBuffer: ArrayBuffer;

  /**
   * Get URL to browsers BLOB
   */
  blobUrl: string;

  /**
   * Converting flag
   */
  converting = false;

  pdfConverter = new ImageToPdfConverter();

  @HostListener('change', ['$event.target.files']) emitFile(
    addedFiles: FileList
  ) {
    this.handleChange(addedFiles.item(0));
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  writeValue(value: File) {
    if (value) {
      if (value.size === 0) {
        this.reset();
        return;
      }

      this.file = value;
    }
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: Function) {}

  handleChange(file: File) {
    const fileSizeMb = (file?.size / (1024 * 1024)).toFixed(2);
    const fileExtension = file?.name.toUpperCase().split('.').pop();
    const eligableToConversion =
      +fileSizeMb <= this.maxSizeToConvertMb &&
      this.validFileTypes.includes(fileExtension);

    if (file) {
      if (eligableToConversion) {
        if (fileExtension === PDF_VALID_FILE_EXT) {
          this.handlePdf(file);
        } else {
          this.handleImages(file);
        }
      } else {
        this.handleFilesNoConversion(file);
      }

      // mandatory to refresh file name in template
      this.changeDetectorRef.markForCheck();
    }
  }

  reset() {
    // clear selected files array
    this.file = null;

    // reset blob url
    this.fileArrayBuffer = null;

    this.revokeBlobUrl();

    this.fileUploadInput.nativeElement.value = '';

    if (typeof this.onChange === 'function') {
      // call registered change listener
      this.onChange(null);
    }
  }

  handleImages(fileToHandle: File) {
    this.converting = true;

    this.pdfConverter
      .generatePdf(fileToHandle)
      .pipe(take(1))
      .subscribe((convertedFile: IGeneratedPdfFiles) => {
        this.revokeBlobUrl();
        this.file = convertedFile.file;
        this.fileArrayBuffer = convertedFile.arrayBuffer;
        this.blobUrl = window.URL.createObjectURL(this.file);
        this.converting = false;
        if (typeof this.onChange === 'function') {
          this.onChange(this.file);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  handlePdf(fileToHandle: File) {
    this.revokeBlobUrl();
    this.file = fileToHandle;
    this.fileArrayBuffer = null;
    this.blobUrl = window.URL.createObjectURL(this.file);

    if (typeof this.onChange === 'function') {
      this.onChange(fileToHandle);
    }
    // Mandatory because of OnPush strategy.
    this.changeDetectorRef.detectChanges();
  }

  handleFilesNoConversion(fileToHandle: File) {
    this.revokeBlobUrl();
    this.file = fileToHandle;
    this.fileArrayBuffer = null;
    this.blobUrl = window.URL.createObjectURL(this.file);

    if (typeof this.onChange === 'function') {
      this.onChange(this.file);
    }

    // Mandatory because of OnPush strategy.
    this.changeDetectorRef.detectChanges();
  }

  revokeBlobUrl() {
    if (this.blobUrl) {
      // revoking blob url
      window.URL.revokeObjectURL(this.blobUrl);
    }

    this.blobUrl = null;
  }

  ngOnDestroy() {
    this.revokeBlobUrl();
  }
}
