import {Directive, TemplateRef, ViewContainerRef, ElementRef, Input} from '@angular/core';
import {AuthCanSomeDirective} from "./auth-can-some.directive";
import {AuthorizationService} from "../services/authorization.service";

@Directive({
  selector: '[authCantSome]'
})
export class AuthCantSomeDirective extends AuthCanSomeDirective {
  @Input() set authCantSome(privilegeStr: string) {
    // set checking mode to negation of given privileges
    this.setAuthCanNot(true);
    // trigger checking
    this.doCheck(privilegeStr);
  }

  constructor(private templateRefX: TemplateRef<unknown>,
              private viewContainerX: ViewContainerRef,
              private elRefX: ElementRef,
              private authorizationServiceX: AuthorizationService) {
    super(templateRefX, viewContainerX, authorizationServiceX);
  }
}
